import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/auth.service';
import {catchError, switchMap, filter, take, tap} from 'rxjs/operators'
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  private isRefreshing = false;
  private refreshJwtCall: Observable<any>
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  refreshJwt() {
    if (this.isRefreshing) {
      return this.refreshJwtCall
    }
    this.isRefreshing = true
    this.refreshJwtCall = this.authenticationService.refreshToken()
    return this.refreshJwtCall
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('Authorization')) {
      return next.handle(request)
    }

    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    /*const refreshToken = this.authenticationService.GetRefreshToken;
    if (currentUser && currentUser.jwt) {
      return next.handle(request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwt}`
        }
      }))
    } else if (!currentUser && refreshToken) {
      this.refreshJwt().toPromise()
      .then(user => {
        return next.handle(request.clone({
          setHeaders: {
            Authorization: `Bearer ${user.jwt}`
          }
        }));
      })
      .catch(_ => {
        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
      }).finally(() => {
        this.isRefreshing = false
      })
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
      return
    }*/


    if (currentUser && currentUser.jwt) {
      request = this.addToken(request, currentUser.jwt);
    } else if (!request.url.endsWith('/users/auth')) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
      return next.handle(request);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      }
      return throwError(error);
    }));


  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      /*return this.authenticationService.refreshToken().pipe(
        tap(user => {
            console.log('handle401Error - tap', user)
        },
        catchError((error: any): Observable<any> => {
          console.log('handle401Error - catchError', error)
          return
        }))
      )*/

      return this.authenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          const currentUser = this.authenticationService.currentUserValue;
          this.isRefreshing = false;
          this.refreshTokenSubject.next(currentUser.jwt);
          return next.handle(this.addToken(request, currentUser.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt)).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              localStorage.clear();
              this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
              return throwError(error);
            }
          }));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.url.endsWith('/users/auth') && (request.method === 'GET' || request.method === 'DELETE')) {
      const refreshToken = this.authenticationService.GetRefreshToken;
      if (refreshToken) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${refreshToken}`
          }
        });
      }
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
