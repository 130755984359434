import { LoaderComponent } from './loader/loader.component';
import { NgModule } from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@NgModule({
    imports: [

    ],
    declarations: [
        LoaderComponent,
        ModalComponent,
        ConfirmationDialogComponent
    ],
    exports: [
        LoaderComponent,
        ModalComponent,
        ConfirmationDialogComponent
    ]
})

export class SharedModule { }