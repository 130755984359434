import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs'
import {catchError, map, tap} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private cookieService: CookieService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        /*if (!localStorage.getItem('currentUser')) {
            this.currentUserSubject.next(null);
            return null;
        }*/
        return this.currentUserSubject.value;
    }

    private wipeLocalStorage() {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('_mhd');
      this.currentUserSubject.next(null)
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/auth`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem('_mhd', user.refreshToken);
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
      const headers = { 'Authorization': `Bearer: ${localStorage.getItem('_mhd')}` }
      return this.http.delete<any>(`${environment.apiUrl}/users/auth`, { headers }).pipe(
        tap(_ => {
          this.wipeLocalStorage()
        }),
        catchError((error: any): Observable<any> => {
          this.wipeLocalStorage()
          return this.currentUser
        })
      );
    }

    get GetRefreshToken(): string {
        return localStorage.getItem('_mhd');
    }

    refreshToken() {
        return this.http.get<any>(`${environment.apiUrl}/users/auth`).pipe(
          tap(user => {
            console.log('refreshToken - tap', user)
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('_mhd', user.refreshToken);
            this.currentUserSubject.next(user);
          }),
          catchError((error: any): Observable<any> => {
            console.log('refreshToken - catchError', error)
            this.wipeLocalStorage()
            return this.currentUser
          })
        );
        /*return this.http.get<any>(`${environment.apiUrl}/users/auth`)
        .pipe(map(user  => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }));*/
      }
}
