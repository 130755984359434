import {Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name:string;
  constructor(private authenticationService:AuthenticationService,private router:Router) {
    this.name=JSON.parse(localStorage.getItem("currentUser")).username;
  }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout().toPromise().finally(() => {
      this.router.navigate(['/login']);
    })
  }
}
