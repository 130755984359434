import {Component, OnInit} from '@angular/core';

declare var $;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],

})
export class SideNavComponent implements OnInit {
name:string;
  constructor() {
    this.name=JSON.parse(localStorage.getItem("currentUser")).username;
  }

  ngOnInit() {
    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
  }

}
