import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
 @Output() delete = new EventEmitter<string>();
 id:any;
  constructor(private modalService: ModalService) {}
  ngOnInit() {}
 
  yes() {
    this.delete.emit(this.id); 
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
